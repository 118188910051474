import React from 'react';

import './button.css';

const Button = (props) => {
  return (
    <a className="button" {...props} />
  );
};

export default Button;
