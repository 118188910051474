import React from 'react';

import './header.css';

import logo from '../assets/logo.png';

const Header = () => {
  return (
    <header>
      <img src={logo} alt="Regym" className="logo"/>
    </header>
  );
};

export default Header;
