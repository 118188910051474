import React from 'react';

import Privacy from '../components/Privacy';

import './privacy.css';

const PrivacyPage = () => {
  return (
    <Privacy />
  );
};

export default Privacy;
