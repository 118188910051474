import { useState, useEffect } from 'react';
import axios from 'axios';

const isDev = process.env.NODE_ENV !== 'production';
const url = isDev
  ? 'http://localhost:5050/regymapp/us-central1/getInvite'
  : 'https://us-central1-regymapp.cloudfunctions.net/getInvite';


const UseInvite = (id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios(`${url}?id=${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Incorrect invite');
        setLoading(false);
      })
  }, [id]);

  return {
    data,
    loading,
    error,
  }
};

export default UseInvite;
