import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './pages/Home';
import Invite from './pages/Invite';
import Privacy from './pages/Privacy';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/invite/:id">
          <Invite />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
