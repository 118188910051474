import React from 'react';
import { useParams } from 'react-router-dom';
import isString from 'lodash/isString';

import useInvite from '../hooks/useInvite';
import './invite.css';

import Header from '../components/Header';
import Button from '../components/Button';

const cropString = (src, length = 100) => {
  if (isString(src)) {
    const content = src.substring(0, length);

    return `${content}...`
  }
  return '';
};

const Invite = () => {
  let { id } = useParams();
  const { data, loading, error } = useInvite(id);
  if (error || loading || !data) return null;

  return (
    <div id="invite">
      <Header />
      <div className="main">
        <div className="container">
          <div className="cover">
            <img src={data.info.cover} alt="" />
          </div>
          <div className="info">
            <div>
              <div className="title">
                {data.info.name}
              </div>
              <div className="description">
                {cropString(data.info.description, 160)}
              </div>
            </div>
            <div className="trainer">
              <img src={data.trainer.photoURL} alt="" className="trainer-avatar"/>
              {data.trainer.name}
            </div>
          </div>
        </div>
      </div>
      <div className="action-block">
        <p className="info-warning">
          Ви можете запускати тренування тільки з додатку. <br />
          Натисніть кнопку нижче щоб завантажити додаток
        </p>
        <Button href={`regym://invite/${id}`}>
          Завантажити з App Store
        </Button>
      </div>
    </div>
  );
};

export default Invite;
